import { Column } from '../models/shared/column';

export enum COLUMNTYPES {
	TEXT = 'text',
	CHECKBOX = 'checkbox',
	RADIOBUTTON = 'radiobutton',
	ICON = 'icon',
	NUMERIC = 'numeric',
	BOOLEAN = 'boolean',
	DATE = 'date',
	DATETIME = 'datetime',
}

export class ColumnConstants {
	static readonly DynamicColumm_ActiveFilter_Error: string = 'Cannot hide a column with an active filter.';

	static populateColumns(defaultColumns: Column[]): Column[] {
		const columns: Column[] = [];
		defaultColumns.forEach(x => {
			columns.push(this.addColumn(x));
		});
		return columns;
	}

	static addColumn(params: any): Column {
		const column = new Column();
		column.order = params.order;
		column.header = params.header;
		column.type = params.type ? params.type : COLUMNTYPES.TEXT;
		column.field = params.field;
		column.isConditional = params.isConditional ?? false;
		column.hasHeaderSmallLabel = params.hasHeaderSmallLabel ?? false;
		column.hasHeaderIcon = params.hasHeaderIcon ?? false;
		column.hasFilterHeaderTemplate = params.hasFilterHeaderTemplate ?? false;
		column.isInnerHtmlFormat = params.isInnerHtmlFormat ?? false;
		column.isSortable = params.isSortable ?? true;
		column.isFilterable = params.isFilterable ?? true;
		column.sortField = params.sortField ? params.sortField : params.field;
		column.filterField = params.filterField ? params.filterField : params.field;
		column.dataTestId = params.dataTestId ? params.dataTestId : 'dtCol' + params.field;
		column.filterdataTestId = params.filterdataTestId;
		column.isFilterCallback = params.isFilterCallback ?? false;
		column.filterType = params.filterType;
		column.matchModeOptions = params.matchModeOptions;
		column.isPrice = params.isPrice ?? false;
		column.width = params.width;
		column.minWidth = params.minWidth ?? 80;
		column.maxWidth = params.maxWidth;
		return column;
	}
}
