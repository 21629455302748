import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageDialogComponent } from 'src/app/shared/components/bulk-action/message-dialog/message-dialog.component';
import { ActionResult, ObjectAccessReason } from 'src/app/shared/models/api';
import { BulkActionBaseService } from 'src/app/shared/services/bulk-action.base.service';
import { ttPermissionType } from 'src/app/core/constants/tooltip-messages.constant';
import { AppContextService, DynamicDialogService, TooltipMessageService } from 'src/app/core/services';
import { RateGroupConstants } from '../constants';
import { RateGroupDetail } from '../models/rate-group-detail.model';
import { RateGroupService } from './rate-group.service';

@Injectable({
	providedIn: 'root',
})
export class RateGroupBulkActionService extends BulkActionBaseService {
	constructor(
		public appContext: AppContextService,
		private rateGroupService: RateGroupService,
		private tooltipMessageService: TooltipMessageService,
		private dialogService: DynamicDialogService
	) {
		super(appContext);
	}

	getBulkActionObjectPrimaryKeyName(): string {
		return 'RateGroupId';
	}

	setFilterActionOptions() {
		this.filterActions = [];

		RateGroupConstants.FilterActionsList.forEach(option => {
			this.filterActions.push(this.addFilterOption(option, option));
		});
	}

	processApiRequest(actionName: string, sel: any, args: any): Observable<ActionResult<any>> {
		switch (actionName) {
			case RateGroupConstants.ActionName_Activate:
				return this.rateGroupService.enableRateGroup(sel.RateGroupId);
			case RateGroupConstants.ActionName_Disable:
				return this.rateGroupService.disableRateGroup(sel.RateGroupId);
			case RateGroupConstants.ActionName_Retire:
				return this.rateGroupService.retireRateGroup(sel.RateGroupId);
			default:
				return null;
		}
	}

	refreshUpdatedValues(resp: ActionResult<any>) {
		const updatedDetails: RateGroupDetail = resp.Data;
		const filteredDetails: RateGroupDetail = this.filteredSelectedItems.find(x => x.RateGroupId == updatedDetails.RateGroupId);
		const selDetails: RateGroupDetail = this.selectedItems.find(x => x.RateGroupId == updatedDetails.RateGroupId);
		if (filteredDetails && selDetails) {
			filteredDetails.StatusCode = updatedDetails.StatusCode;
			filteredDetails.StatusName = updatedDetails.StatusName;
			filteredDetails.ObjectAccess = updatedDetails.ObjectAccess;

			selDetails.StatusCode = updatedDetails.StatusCode;
			selDetails.StatusName = updatedDetails.StatusName;
			selDetails.ObjectAccess = updatedDetails.ObjectAccess;
		}
		return;
	}

	showBulkAction(item: RateGroupDetail): boolean {
		if (!this.filterActionSelected) return false;
		const actionName = this.filterActionSelected?.NameValue ?? (this.filterActionSelected as unknown as string);

		const access = this.getActionAccess(item, actionName);
		return access?.IsAllowed;
	}

	allowBulkAction(item: RateGroupDetail): boolean {
		if (!this.filterActionSelected) return false;
		const actionName = this.filterActionSelected?.NameValue ?? (this.filterActionSelected as unknown as string);

		const access = this.getActionAccess(item, actionName);
		return access?.IsAllowed;
	}

	private getActionAccess(item: RateGroupDetail, actionName: string): ObjectAccessReason {
		const actionAccessName = RateGroupConstants.ActionPermissionNames.find(ap => ap.action == actionName)?.permission;
		if (actionAccessName) return item?.ObjectAccess?.[actionAccessName];

		return null;
	}

	getPadlockTooltip(item: RateGroupDetail): string {
		if (!this.filterActionSelected) return '';
		const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;

		const actionAccessName = RateGroupConstants.ActionPermissionNames.find(ap => ap.action == actionName)?.permission;
		return this.tooltipMessageService.getTooltipMessage('C_RATEGROUP', actionAccessName as ttPermissionType, item?.ObjectAccess?.[actionAccessName], item, {
			actionName: actionName,
		} as any);
	}

	showErrorDialog(item: RateGroupDetail) {
		const selItem = this.bulkActionResults.find(x => x.Details.RateGroupId == item.RateGroupId);
		if (!selItem) return;

		this.messageObject = selItem;
		this.dialogService.open({
			component: MessageDialogComponent,
			header: RateGroupConstants.MessageDialog_Header_Error,
			styleClass: 'error-message-dialog-custom',
			contentStyle: { overflow: 'auto' },
			data: {
				title: RateGroupConstants.MessageDialog_Title_Error,
				icon: RateGroupConstants.Message_Title_Icon_Error,
				messageObject: this.messageObject,
				ObjectName: RateGroupConstants.BulkAction_Object_Name,
				ObjectPropertyName_Number: RateGroupConstants.BulkAction_Object_PropertyName,
			},
		});
	}

	actionIcons(actionName): string {
		return RateGroupConstants.ActionIconsNames.find(ai => ai.action == actionName)?.icon || '';
	}

	handleApplyClick(event: any) {
		if (this.isBulkActionView) {
			const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;
			const confirmationMessage = RateGroupConstants.BulkAction_Confirmation_Message_Generic(
				this.filteredSelectedItems.length.toString(),
				actionName.toString()
			);

			const confirmationHeader = RateGroupConstants.Confirmation_Header_Generic(actionName.toString());
			const confirmationIcon = this.actionIcons(actionName);

			this.util.clearMessages();
			this.util.confirmSaveCustomParams(confirmationMessage, confirmationHeader, confirmationIcon).then(result => {
				if (result) {
					this.startBulkAction(null);
					return true;
				}
			});
		}
	}

	//This is done to maintain UI checkbox select all checked
	onSelectionChange(items: any[]) {
		this.filteredSelectedItems = [];
		for (let i = items.length - 1; i >= 0; i--) {
			const item = items[i];
			if (this.allowBulkAction(item)) {
				this.filteredSelectedItems.push(item);
			}
		}
	}

	errorDialogClosed() {
		this.showMessage = false;
	}
}
