import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@fullcalendar/core/internal';
import { au } from '@fullcalendar/core/internal-common';
import { ComponentChild, RenderableProps } from '@fullcalendar/core/preact';
import { Table } from 'primeng/table';
import { RateGroupDetail } from 'src/app/features/rate/rate-group/models';
import { RateGroupService } from 'src/app/features/rate/rate-group/services';
import { AppContextService, NavigationService, UtilService } from 'src/app/core/services';
import { LazyLoadArgs } from '../../models/api';
import { FormMode } from '../../models/forms';

@Component({
	selector: 'app-related-rategroup',
	templateUrl: './related-rategroup.component.html',
	styleUrls: ['./related-rategroup.component.scss'],
})
export class RelatedRateGroupComponent extends BaseComponent {
	render(props?: RenderableProps<au, any>, state?: Readonly<au>, context?: any): ComponentChild {
		throw new Error('Method not implemented.');
	}

	@Input() referenceTypeCode: string;
	@Input() referenceId: string;

	@ViewChild('relatedRateGroup') relatedRateGroupflowHistoryTable: Table;

	relatedRateGroupDetails: RateGroupDetail[] = [];

	projectId: string;
	areaId: string;
	clientId: string;
	companyId: string;

	totalRecords = 0;
	formMode = FormMode.View;
	formModes = FormMode;

	item: RateGroupDetail;
	showList = true;
	refreshRequired = false;
	timeZoneCode: string;
	isClientUser: boolean;
	public util: UtilService;
	constructor(appContext: AppContextService, private rateGroupService: RateGroupService, private router: Router, navigationService: NavigationService) {
		super(appContext);
	}

	ngOnInit(): void {
		try {
			this.formMode = this.formModes.View;

			const event = new LazyLoadArgs();
			this.rateGroupService.getPagedListForReference(event, this.referenceTypeCode, this.referenceId).subscribe(result => {
				this.relatedRateGroupDetails = result.Data;
				this.showList = true;
			});
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	public refreshData() {
		try {
			if (this.relatedRateGroupflowHistoryTable) this.relatedRateGroupflowHistoryTable.editingRowKeys = {};
			const event = new LazyLoadArgs();
			this.rateGroupService.getPagedListForReference(event, this.referenceTypeCode, this.referenceId).subscribe(result => {
				this.relatedRateGroupDetails = result.Data;
				this.showList = true;
			});
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	canNavigateToDetails(dto: RateGroupDetail) {
		return this.getNavigationRoute(dto) != null;
	}

	handleRowClick(dto: RateGroupDetail) {
		const route = this.getNavigationRoute(dto);
		if (route) this.router.navigate(route);
	}

	getNavigationRoute(dto: RateGroupDetail) {
		if (dto.RateGroupId != null) return ['/rate/rate-group/', dto.RateGroupId];
		return null;
	}

	showLink(): boolean {
		return !this.isClientUser;
	}
}
