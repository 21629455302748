<p-toolbar *ngIf="showActionsToolbar">
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="select-button-toolbar">
			<p-selectButton
				[options]="displayFilterOptions"
				(onChange)="changeFilterOption($event)"
				[(ngModel)]="selectedFilterOption"
				optionLabel="label"
				optionValue="value"
				data-testid="displayFilterOptionsSelectButton"></p-selectButton>
		</div>
	</ng-template>
</p-toolbar>
<p-table
	data-testid="auditHistoryList"
	#auditHistoryTable
	[resizableColumns]="true"
	columnResizeMode="fit"
	[value]="data"
	responsiveLayout="scroll"
	[showCurrentPageReport]="true"
	[lazy]="true"
	[paginator]="true"
	[totalRecords]="totalRecords"
	[filterDelay]="0"
	[loading]="loading"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[rowsPerPageOptions]="[10, 25, 50]"
	[rows]="10"
	(onLazyLoad)="loadData($event)">
	<ng-template pTemplate="header">
		<tr>
			<th pSortableColumn="ReferenceTypeName" pResizableColumn>
				<div class="table-header-label">
					Reference Type
					<p-sortIcon field="ReferenceTypeName"></p-sortIcon>
					<p-columnFilter type="text" field="ReferenceTypeName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ReferenceName" pResizableColumn>
				<div class="table-header-label">
					Reference
					<p-sortIcon field="ReferenceName"></p-sortIcon>
					<p-columnFilter type="text" field="ReferenceName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="FieldDisplayName" pResizableColumn>
				<div class="table-header-label">
					Description
					<p-sortIcon field="FieldDisplayName"></p-sortIcon>
					<p-columnFilter type="text" field="FieldDisplayName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="OldValue" pResizableColumn>
				<div class="table-header-label">
					Changed From
					<p-sortIcon field="OldValue"></p-sortIcon>
					<!-- canot have filter on this field -->
				</div>
			</th>
			<th pSortableColumn="NewValue" pResizableColumn>
				<div class="table-header-label">
					Changed To
					<p-sortIcon field="NewValue"></p-sortIcon>
					<!-- canot have filter on this field -->
				</div>
			</th>
			<th pSortableColumn="DateAdded" pResizableColumn>
				<div class="table-header-label">
					Created
					<p-sortIcon field="DateAdded"></p-sortIcon>
					<p-columnFilter type="date" field="DateAdded" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="AddedBy" pResizableColumn>
				<div class="table-header-label">
					Created by
					<p-sortIcon field="AddedBy"></p-sortIcon>
					<p-columnFilter type="text" field="AddedBy" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-auditHistoryDetail>
		<tr>
			<td>{{ auditHistoryDetail.ReferenceTypeName }}</td>
			<td>{{ auditHistoryDetail.ReferenceName }}</td>
			<td>
				<div *ngIf="auditHistoryDetail.IsAction" class="pi pi-star">&nbsp;</div>
				{{ auditHistoryDetail.FieldDisplayName }}
			</td>
			<td *ngIf="isPrice(auditHistoryDetail)" style="text-align: right">
				<div
					pTooltip="{{ auditHistoryDetail.OldValue | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode }}"
					tooltipPosition="bottom">
					{{ auditHistoryDetail.OldValue | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode }}
				</div>
			</td>
			<td *ngIf="isPrice(auditHistoryDetail)" style="text-align: right">
				<div
					pTooltip="{{ auditHistoryDetail.NewValue | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode }}"
					tooltipPosition="bottom">
					{{ auditHistoryDetail.NewValue | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode }}
				</div>
			</td>
			<td *ngIf="isPrice(auditHistoryDetail) == false">
				<div pTooltip="{{ auditHistoryDetail.OldValue }}" tooltipPosition="bottom">
					{{ auditHistoryDetail.OldValue | truncate }}
				</div>
			</td>
			<td *ngIf="isPrice(auditHistoryDetail) == false">
				<div pTooltip="{{ auditHistoryDetail.NewValue }}" tooltipPosition="bottom">
					{{ auditHistoryDetail.NewValue | truncate }}
				</div>
			</td>
			<td><span [innerHTML]="auditHistoryDetail.DateAdded | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span></td>
			<td>
				{{ auditHistoryDetail.AddedBy }}
				<span class="icon-container" [pTooltip]="getUserRelationTypeTooltip(auditHistoryDetail.AddedByExternalUserRelationTypeCode)">
					<i [ngClass]="getUserRelationTypeIcon(auditHistoryDetail.AddedByExternalUserRelationTypeCode)"></i>
				</span>
			</td>
		</tr>
	</ng-template>
</p-table>
