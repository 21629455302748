<p-table
	#relatedRateGroup
	*ngIf="showList"
	[value]="relatedRateGroupDetails"
	dataKey="data"
	[rowHover]="true"
	responsiveLayout="scroll"
	[showCurrentPageReport]="true"
	[loading]="loading"
	[filterDelay]="0">
	<ng-template pTemplate="header">
		<tr class="related-rategroup-table-row">
			<th pSortableColumn="Name" data-testid="relatedRateGroupName">
				<div class="table-header-label">
					Name<p-sortIcon field="Name"> </p-sortIcon>
					<p-columnFilter type="text" field="Name" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="StatusName" data-testid="relatedRateGroupStatus">
				<div class="table-header-label">
					Status<p-sortIcon field="StatusName"> </p-sortIcon>
					<p-columnFilter type="text" field="StatusName" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Description" data-testid="relatedRateGroupDescription">
				<div class="table-header-label">
					Description<p-sortIcon field="Description"> </p-sortIcon>
					<p-columnFilter type="text" field="Description" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="IsDefault" data-testid="relatedRateGroupIsDefault">
				<div class="table-header-label">
					Is Default<p-sortIcon field="IsDefault"></p-sortIcon>
					<p-columnFilter type="value" field="IsDefault" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="OrderIndex" data-testid="relatedRateGroupOrderIndex">
				<div class="table-header-label">
					Order Index<p-sortIcon field="OrderIndex"></p-sortIcon>
					<p-columnFilter type="text" field="OrderIndex" display="menu" class="p-ml-auto"> </p-columnFilter>
				</div>
			</th>

			<th *ngIf="showLink()" style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rateGroupDetail>
		<tr class="rate-group-detail-row">
			<td>{{ rateGroupDetail.Name }}</td>
			<td>{{ rateGroupDetail.StatusName }}</td>
			<td>{{ rateGroupDetail.Description }}</td>
			<td>{{ rateGroupDetail.IsDefault }}</td>
			<td>{{ rateGroupDetail.OrderIndex }}</td>

			<td *ngIf="showLink()" style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(rateGroupDetail)"
					type="button"
					pButton
					pRipple
					pTooltip="Click For {{ rateGroupDetail.Name ?? '' }}"
					tooltipPosition="bottom"
					(click)="handleRowClick(rateGroupDetail)"
					class="p-button-text p-button-rounded p-button-plain"
					icon="pi pi-link"></button>
				<div
					*ngIf="!canNavigateToDetails(rateGroupDetail)"
					class="p-element p-button-text p-button-rounded p-button-plain p-button p-component p-button-icon-only ng-star-inserted"></div>
			</td>
		</tr>
	</ng-template>
</p-table>
<div *ngIf="showList" class="custom-pagination">
	<span>Showing {{ relatedRateGroupDetails == null ? 0 : relatedRateGroupDetails.length }} entries</span>
</div>
