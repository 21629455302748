<p-toolbar>
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showDelete() && formMode == formModes.View" pTooltip="Delete Contact" tooltipPosition="bottom">
			<p-button icon="pi pi-trash" (onClick)="deleteItem()" label="Delete" data-testid="contactDetailsDeleteButton"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Create, formModes.Edit].includes(formMode)" pTooltip="Save Contact" tooltipPosition="bottom">
			<p-button label="Save" (onClick)="saveItem()" icon="pi pi-save" data-testid="contactDetailsSaveButton"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="showEdit() && [formModes.View].includes(formMode)" pTooltip="Edit Contact" tooltipPosition="bottom">
			<p-button (onClick)="editItem()" icon="pi pi-pencil" label="Edit" data-testid="contactDetailsEditButton"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Edit].includes(formMode)" pTooltip="Cancel Edit" tooltipPosition="bottom">
			<p-button label="Cancel" (onClick)="cancelItemEdit()" icon="pi pi-ban"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Create].includes(formMode)" pTooltip="Cancel and Back To Contacts List" tooltipPosition="bottom">
			<p-button label="Cancel" (onClick)="backToList()" icon="pi pi-ban"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.View].includes(formMode)" pTooltip="Back To Contacts List" tooltipPosition="bottom">
			<p-button label="Back" (onClick)="backToList()" icon="pi pi-arrow-left" data-testid="contactDetailsBackToListButton"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<form [formGroup]="contactForm" #formRef="ngForm" (ngSubmit)="saveItem()" novalidate>
	<div class="p-grid p-formgrid p-m-0 p-p-0">
		<!-- row 1 -->

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="contactForm.get('name')"
				label="Name"
				data-testid="contactDetailsName">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[controlType]="formControls.Calendar"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[control]="contactForm.get('dateActiveFrom')"
				label="Active From"
				[timeZoneCode]="item?.TimeZoneCode">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[controlType]="formControls.Calendar"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[control]="contactForm.get('dateActiveTo')"
				label="Active To"
				[timeZoneCode]="item?.TimeZoneCode">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<!-- row 2 -->

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="contactForm.get('description')"
				label="Description">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="contactForm.get('phoneNumber')"
				label="Phone #">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="contactForm.get('mobileNumber')"
				label="Mobile #">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<!-- row 3 -->

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[lookupValues]="contactTypes"
				[control]="contactForm.get('contactType')"
				[label]="contactTypeLabel">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="contactForm.get('emailAddress')"
				label="Email">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[lookupValues]="languages"
				[control]="contactForm.get('language')"
				label="Language">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<!-- row 4 -->

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.CheckBox"
				label="Is Primary Contact"
				[control]="contactForm.get('isPrimary')">
			</app-form-item>
		</div>
		<div class="p-col-6 p-md-0 form-element-area">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textarea"
				[control]="contactForm.get('accessDetails')"
				label="Access Details">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<!-- row -->

		<div class="p-col-12 p-md-0 form-element" *ngIf="[formModes.View, formModes.Edit].includes(formMode)"></div>

		<!-- row -->

		<div class="p-col-3 p-md-0 form-element" *ngIf="[formModes.View, formModes.Edit].includes(formMode)">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="contactForm.get('addedBy')"
				label="Created by"
				[iconAfterTextboxReadOnly]="getUserRelationTypeIcon(item?.AddedByExternalUserRelationTypeCode)"
				[iconAfterTextboxTooltip]="getUserRelationTypeTooltip(item?.AddedByExternalUserRelationTypeCode)">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="[formModes.View, formModes.Edit].includes(formMode)">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="contactForm.get('dateAdded')"
				label="Created">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="[formModes.View, formModes.Edit].includes(formMode)">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="contactForm.get('updatedBy')"
				label="Last Updated by"
				[iconAfterTextboxReadOnly]="getUserRelationTypeIcon(item?.UpdatedByExternalUserRelationTypeCode)"
				[iconAfterTextboxTooltip]="getUserRelationTypeTooltip(item?.UpdatedByExternalUserRelationTypeCode)">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="[formModes.View, formModes.Edit].includes(formMode)">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="contactForm.get('dateUpdated')"
				label="Last Updated">
			</app-form-item>
		</div>

		<div class="p-col-3 p-md-0 form-element" *ngIf="contactForm.get('dateRemoved').value"></div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="contactForm.get('dateRemoved').value"></div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="contactForm.get('dateRemoved').value"></div>
		<div class="p-col-3 p-md-0 form-element" *ngIf="contactForm.get('dateRemoved').value">
			<app-form-item [controlType]="formControls.Calendar" [control]="contactForm.get('dateRemoved')" label="Deleted"> </app-form-item>
		</div>
	</div>
</form>
