export const environment = {
	production: false,
	// eslint-disable-next-line
	appVersion: require('../../package.json').version + '-dev',
	apiUrl: 'https://apim-sf-dev-aue.azure-api.net',
	b2cTenant: 'spydertechiamdev',
	b2cClientId: 'bf2236c0-5eff-4a3e-ab9d-ccd60167d52b',
	b2cRedirectUri: 'https://dev.spyderflow.com.au/auth',
	showDevHelpers: true,
	signalRNotificationHub: 'https://apim-sf-dev-aue.azure-api.net/notificationHub',
	signalRLogging: 0, // trace
};
