import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule } from 'primeng/fieldset';
import { MultiSelectModule } from 'primeng/multiselect';
import { LocaleService } from '../core/services/locale.service';
import { CoreModule } from './../core/core.module';
import { AdvancedFilterComponent } from './components/advanced-filter/advanced-filter.component';
import { AssetHistoryComponent } from './components/asset/asset-history/asset-history.component';
import { GoogleAddressComponent } from './components/asset/asset-select/google-address/google-address.component';
import { AttachmentDetailsComponent } from './components/attachment/attachment-details/attachment-details.component';
import { AttachmentListComponent } from './components/attachment/attachment-list/attachment-list.component';
import { AuditHistoryComponent } from './components/audit-history/audit-history.component';
import { BankDetailComponent } from './components/bank-detail/bank-detail.component';
import { MessageDialogComponent } from './components/bulk-action/message-dialog/message-dialog.component';
import { EntityInsuranceDetailComponent } from './components/business-entity/entity-insurance-detail/entity-insurance-detail.component';
import { EntityInsuranceTableComponent } from './components/business-entity/entity-insurance-table/entity-insurance-table.component';
import { EntityLicenceDetailComponent } from './components/business-entity/entity-licence-detail/entity-licence-detail.component';
import { EntityLicenceTableComponent } from './components/business-entity/entity-licence-table/entity-licence-table.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ChipsComponent } from './components/chips/chips.component';
import { ContactDetailsComponent } from './components/contact-person/contact-details/contact-details.component';
import { ContactPersonTableComponent } from './components/contact-person/contact-person-table/contact-person-table.component';
import { ChipsMultiselectComponent } from './components/custom-chips-multiselect/custom-chips-multiselect.component';
import { DialogFooterComponent } from './components/dialog-footer/dialog-footer.component';
import { DynamicColumnListComponent } from './components/dynamic-column-list/dynamic-column-list.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { FlowHistoryComponent } from './components/flow-history/flow-history.component';
import { FormItemComponent } from './components/form-item/form-item.component';
import { GMapComponent } from './components/gmap/gmap';
import { InlineDateTimePickerComponent } from './components/inline-date-time-picker/inline-date-time-picker.component';
import { MultiselectListDialogComponent } from './components/multiselect-list-dialog/multiselect-list-dialog.component';
import { NoteDetailComponent } from './components/notes/note-detail/note-detail.component';
import { NoteTableComponent } from './components/notes/note-table/note-table.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RelatedInvoiceItemListComponent } from './components/related-invoice/related-invoice-item-list/related-invoice-item-list.component';
import { RelatedInvoiceTableComponent } from './components/related-invoice/related-invoice-table/related-invoice-table.component';
import { RelatedRateGroupComponent } from './components/related-rategroup/related-rategroup.component';
import { SplitButtonComponent } from './components/split-button/split-button.component';
import { SummaryFlagsComponent } from './components/summary-flags/summary-flags.component';
import { ArrayConcatPipe } from './pipes/array-concat.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { TruncatedPipe } from './pipes/truncated.pipe';
import { AttachmentService } from './services/attachment.service';

const SHARED_PIPES = [TruncatePipe, TruncatedPipe, FormatDatePipe, FormatNumberPipe, FormatTimePipe, ArrayConcatPipe];

@NgModule({
	imports: [CoreModule, FieldsetModule, ReactiveFormsModule, MultiSelectModule, ChipsModule, ChipModule],
	declarations: [
		...SHARED_PIPES,
		AdvancedFilterComponent,
		AssetHistoryComponent,
		ContactDetailsComponent,
		NoteDetailComponent,
		GoogleAddressComponent,
		AuditHistoryComponent,
		ContactPersonTableComponent,
		NoteTableComponent,
		AttachmentListComponent,
		AttachmentDetailsComponent,
		FormItemComponent,
		InlineDateTimePickerComponent,
		SummaryFlagsComponent,
		RelatedInvoiceTableComponent,
		RelatedInvoiceItemListComponent,
		ProgressBarComponent,
		FlowHistoryComponent,
		EntityLicenceTableComponent,
		EntityLicenceDetailComponent,
		EntityInsuranceTableComponent,
		EntityInsuranceDetailComponent,
		GMapComponent,
		CalendarComponent,
		ChipsComponent,
		ChipsMultiselectComponent,
		DialogFooterComponent,
		MultiselectListDialogComponent,
		PageNotFoundComponent,
		BankDetailComponent,
		MessageDialogComponent,
		DynamicColumnListComponent,
		SplitButtonComponent,
		DynamicTableComponent,
		RelatedRateGroupComponent,
	],
	exports: [
		...SHARED_PIPES,
		AdvancedFilterComponent,
		AssetHistoryComponent,
		ContactDetailsComponent,
		NoteDetailComponent,
		AuditHistoryComponent,
		NoteTableComponent,
		AttachmentListComponent,
		FormItemComponent,
		InlineDateTimePickerComponent,
		SummaryFlagsComponent,
		GoogleAddressComponent,
		ContactPersonTableComponent,
		RelatedInvoiceTableComponent,
		RelatedInvoiceItemListComponent,
		ProgressBarComponent,
		FlowHistoryComponent,
		EntityLicenceTableComponent,
		EntityLicenceDetailComponent,
		EntityInsuranceTableComponent,
		EntityInsuranceDetailComponent,
		GMapComponent,
		CalendarComponent,
		ChipsComponent,
		ChipsMultiselectComponent,
		DialogFooterComponent,
		MultiselectListDialogComponent,
		PageNotFoundComponent,
		BankDetailComponent,
		MessageDialogComponent,
		DynamicColumnListComponent,
		SplitButtonComponent,
		DynamicTableComponent,
		RelatedRateGroupComponent,
	],
	providers: [
		AttachmentService,
		{
			provide: LOCALE_ID,
			useFactory: (localeService: LocaleService) => {
				localeService.registerCulture();
			},
			deps: [LocaleService],
		},
	],
})
export class SharedModule {}
