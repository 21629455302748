export class AuditHistory {
	HistoryId: string;

	ReferenceTypeCode: string;
	ReferenceId: string;
	ReferenceName: string;
	ReferenceTypeName: string;
	ParentReferenceTypeCode: string;
	ParentReferenceId: string;
	ParentReferenceName: string;
	TransactionId: string;
	FieldSourceName: string;
	FieldDisplayName: string;
	OldValue: string;
	NewValue: string;
	ShowToUser: boolean;
	HistoryFieldTypeCode: string;
	IsAction: boolean;
	TimeZoneCode: string;
	TimeZoneName: string;
	AddedById: string;
	AddedBy: string;
	DateAdded: Date;
	AddedByExternalUserRelationTypeCode: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
