import { Component, OnInit } from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService } from 'src/app/core/services';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../main/app.main.component';

const faIcons = {
	home: 'fas fa-home', // PrimeIcons.HOME
	scope: 'fas fa-list-alt', // PrimeIcons.FILE
	workorders: 'fas fa-hammer', // 'lake-icon icon-wo'
	purchaseorders: 'fas fa-dolly-flatbed', // 'lake-icon icon-po'
	quotes: 'fas fa-pencil-ruler', // PrimeIcons.CHECK_SQUARE
	contractors: 'fas fa-hard-hat', // PrimeIcons.BRIEFCASE
	properties: 'fas fa-building', // PrimeIcons.HOME
	projects: 'fas fa-file-signature', // PrimeIcons.LIST
	invoices: 'fas fa-money-check', // invoices
	devTools: 'fas fa-bug', // dev tools
	subscriptionsettings: 'fas fa-user-cog', //subscription settings
	externalusers: 'fas fa-user-shield', //external users
	servicerequests: 'fas fa-people-carry', //service requests
	contacts: 'fas fa-address-book', //contacts
	employees: 'fas fa-user-clock', //employees
	suppliers: 'fas fa-store', //suppliers
	clients: 'fas fa-hands-helping', //clients
	systeminterface: 'fas fa-microchip', //system interface
	incomingevents: 'fas fa-cloud-download-alt', //incoming events
	outgoingevents: 'fas fa-cloud-upload-alt', //ougoing events
	receivables: 'fas fa-receipt', //receivables
	payables: 'fas fa-credit-card', //payables
	assets: 'fab fa-autoprefixer', // assets
	rates: 'fas fa-clipboard-list', // rates
	rateitems: 'fas fa-border-style', // items
	rategroups: 'fas fa-border-none', // groups
	problems: 'fas fa-exclamation-triangle', // problems
};

@Component({
	selector: 'app-menu',
	templateUrl: './app.menu.component.html',
})
export class AppMenuComponent extends BaseComponent implements OnInit {
	model: any[];

	constructor(public app: AppComponent, public appMain: AppMainComponent, appContext: AppContextService) {
		super(appContext);
		this.appContext.userChanged.pipe(takeUntil(this.destroyed$)).subscribe(x => this.refreshPage());
	}

	ngOnInit() {
		this.refreshPage();
	}

	onMenuClick() {
		this.appMain.menuClick = true;
	}

	refreshPage() {
		if (this.appContext.user?.SubscriberId) {
			this.model = this.getModel();
		} else {
			this.model = [];
		}
	}

	getModel(): any {
		const baseModel = {
			items: [
				{
					label: 'Dashboard',
					icon: PrimeIcons.CHART_BAR,
					permissionCode: Constants.UserPermission.DashboardView,
					routerLink: ['/dashboard'],
				},
				{
					label: 'Service Requests',
					icon: faIcons.servicerequests,
					permissionCode: Constants.UserPermission.ServiceRequestView,
					routerLink: ['/service-request'],
					styleClass: 'app-sidebar-nav-servicerequest-holder',
				},
				{
					label: 'Scopes',
					icon: faIcons.scope,
					permissionCode: Constants.UserPermission.ScopeView,
					routerLink: ['/scope'],
					styleClass: 'app-sidebar-nav-scope-holder',
				},
				{
					label: 'Quotes',
					icon: faIcons.quotes,
					permissionCode: Constants.UserPermission.QuoteView,
					routerLink: ['/quote'],
					styleClass: 'app-sidebar-nav-quote-holder',
				},
				{
					label: 'Work Orders',
					icon: faIcons.workorders,
					permissionCode: Constants.UserPermission.OrderView,
					routerLink: ['/work-order'],
					styleClass: 'app-sidebar-nav-workorder-holder',
				},
				{
					label: 'Purchase Orders',
					icon: faIcons.purchaseorders,
					permissionCode: Constants.UserPermission.OrderView,
					routerLink: ['/purchase-order'],
					styleClass: 'app-sidebar-nav-purchaseorder-holder',
				},
				{
					label: 'Invoices',
					icon: faIcons.invoices,
					styleClass: 'app-sidebar-nav-receivables-holder',
					items: [
						{
							label: 'Receivables',
							icon: faIcons.receivables,
							permissionCode: Constants.UserPermission.InvoiceView,
							routerLink: ['/invoice/receivable'],
							styleClass: 'app-sidebar-nav-receivables-holder',
						},
						{
							label: 'Payables',
							icon: faIcons.payables,
							permissionCode: Constants.UserPermission.InvoiceView,
							routerLink: ['/invoice/payable'],
							styleClass: 'app-sidebar-nav-payables-holder',
						},
					],
				},
				{
					label: 'Assets',
					icon: faIcons.assets,
					permissionCode: Constants.UserPermission.AssetView,
					routerLink: ['/asset'],
					styleClass: 'app-sidebar-nav-asset-holder',
				},
				{
					label: 'Properties',
					icon: faIcons.properties,
					permissionCode: Constants.UserPermission.AssetView,
					routerLink: ['/property'],
					styleClass: 'app-sidebar-nav-property-holder',
				},
				{ label: 'Reports', icon: PrimeIcons.CHART_LINE, permissionCode: Constants.UserPermission.ReportView, routerLink: ['report'] },
				{
					label: 'Calendar',
					icon: PrimeIcons.CALENDAR,
					permissionCode: Constants.UserPermission.CalendarView,
					routerLink: ['/calendar'],
					styleClass: 'app-sidebar-nav-calendar-holder',
				},
				{
					label: 'Contacts',
					icon: faIcons.contacts,
					styleClass: 'app-sidebar-nav-contact-holder',
					items: [
						{
							label: 'Clients',
							icon: faIcons.clients,
							permissionCode: Constants.UserPermission.ClientView,
							routerLink: ['/contact/client'],
							styleClass: 'app-sidebar-nav-contact-holder',
						},
						{
							label: 'Contractors',
							icon: faIcons.contractors,
							permissionCode: Constants.UserPermission.BusinessEntityView,
							routerLink: ['/contact/contractor'],
							styleClass: 'app-sidebar-nav-contact-holder',
						},
						{
							label: 'Employees',
							icon: faIcons.employees,
							permissionCode: Constants.UserPermission.BusinessEntityView,
							routerLink: ['/contact/employee'],
							styleClass: 'app-sidebar-nav-contact-holder',
						},
						{
							label: 'Suppliers',
							icon: faIcons.suppliers,
							permissionCode: Constants.UserPermission.BusinessEntityView,
							routerLink: ['/contact/supplier'],
							styleClass: 'app-sidebar-nav-contact-holder',
						},
					],
				},
				{
					label: 'Rates',
					icon: faIcons.rates,
					styleClass: 'app-sidebar-nav-sor-holder',
					permissionCode: Constants.UserPermission.SorView,
					items: [
						{
							label: 'Rate Groups',
							icon: faIcons.rategroups,
							routerLink: ['/rate/rate-group'],
							styleClass: 'app-sidebar-nav-sor-holder',
						},
						{
							label: 'Items',
							icon: faIcons.rateitems,
							routerLink: ['/rate/item'],
							styleClass: 'app-sidebar-nav-sor-holder',
						},
						{
							label: 'Problems',
							icon: faIcons.problems,
							routerLink: ['/rate/problem'],
							styleClass: 'app-sidebar-nav-sor-holder',
						},
					],
				},
				{
					label: 'Settings',
					icon: PrimeIcons.COG,
					items: [
						{
							label: 'Companies',
							icon: PrimeIcons.ID_CARD,
							permissionCode: Constants.UserPermission.CompanyView,
							routerLink: ['/setting/company'],
						},
						{
							label: 'Areas',
							icon: PrimeIcons.GLOBE,
							permissionCode: Constants.UserPermission.AreaView,
							routerLink: ['/setting/area'],
						},
						{
							label: 'Projects',
							icon: faIcons.projects,
							permissionCode: Constants.UserPermission.ProjectView,
							routerLink: ['/setting/project'],
						},
						{
							label: 'Fields',
							icon: PrimeIcons.TAGS,
							permissionCode: Constants.UserPermission.DomainValueView,
							routerLink: ['/setting/field'],
						},
					],
				},
				{
					label: 'System Interface',
					icon: faIcons.systeminterface,
					items: [
						{
							label: 'Incoming Events',
							icon: faIcons.incomingevents,
							permissionCode: Constants.UserPermission.InterfaceView,
							routerLink: ['/system-interface/incoming-event'],
						},
						{
							label: 'Outgoing Events',
							icon: faIcons.outgoingevents,
							permissionCode: Constants.UserPermission.InterfaceView,
							routerLink: ['/system-interface/outgoing-event'],
						},
					],
				},
				{
					label: 'Account Admin',
					icon: PrimeIcons.USERS,
					items: [
						{
							label: 'Subscriber Settings',
							icon: faIcons.subscriptionsettings,
							permissionCode: Constants.UserPermission.SubscriberView,
							routerLink: ['/account-admin/setting'],
						},
						{ label: 'Users', icon: PrimeIcons.USER, permissionCode: Constants.UserPermission.UserView, routerLink: ['/account-admin/user'] },
						{
							label: 'External Users',
							icon: faIcons.externalusers,
							permissionCode: Constants.UserPermission.UserView,
							routerLink: ['/account-admin/external-user'],
						},
						{
							label: 'User Roles',
							icon: PrimeIcons.ID_CARD,
							permissionCode: Constants.UserPermission.UserRoleView,
							routerLink: ['/account-admin/user-role'],
						},
					],
				},
			],
		};

		if (environment.showDevHelpers) {
			baseModel.items.push({
				label: 'Dev Tools',
				icon: faIcons.devTools,
				permissionCode: null,
				routerLink: ['/dev-tool'],
			});
		}

		this.applyPermissions(baseModel);

		return baseModel.items;
	}

	applyPermissions(model: any) {
		if (model.items == null) return;

		// top-level filter first
		model.items = model.items.filter(x => this.appContext.hasPermission(x.permissionCode));

		// then check individual items
		model.items.forEach(x => this.applyPermissions(x));

		// then re-filter for empty sub-item list
		model.items = model.items.filter(x => x.items == null || x.items.length > 0);
	}
}
