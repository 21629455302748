import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult, LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { ExportOptions } from 'src/app/shared/models/ui';
import { ApiService, UtilService } from 'src/app/core/services';
import { RateGroupDetail } from '../models/rate-group-detail.model';

@Injectable({
	providedIn: 'root',
})
export class RateGroupService {
	constructor(private apiService: ApiService, private utilService: UtilService) {}

	getPagedList(searchData: LazyLoadArgs): Observable<PagedResponse<RateGroupDetail>> {
		return this.apiService.post('/rateGroup/GetPagedList', searchData) as Observable<PagedResponse<RateGroupDetail>>;
	}

	getPagedListForReference(searchData: LazyLoadArgs, referenceTypeCode: string, referenceId: string): Observable<PagedResponse<RateGroupDetail>> {
		const params = new HttpParams().set('referenceTypeCode', referenceTypeCode).set('referenceId', referenceId);
		return this.apiService.post('/rateGroup/GetPagedListForReference', searchData, params) as Observable<PagedResponse<RateGroupDetail>>;
	}

	getPagedListExcelFile(exportOptions: ExportOptions): Observable<ActionResult> {
		return this.apiService.post('/rateGroup/GetPagedListExcelFile', exportOptions) as Observable<ActionResult>;
	}

	getRateGroupDetails(rateGroupId: string): Observable<RateGroupDetail> {
		return this.apiService.get<RateGroupDetail>(`/rateGroup/GetRateGroupDetails/${rateGroupId}`);
	}

	saveRateGroupDetails(rateGroupDetail: RateGroupDetail) {
		return this.apiService.post<ActionResult>('/rateGroup/SaveRateGroupDetails', rateGroupDetail);
	}

	disableRateGroup(rateGroupId: string) {
		return this.apiService.get<ActionResult>(`/rateGroup/DisableRateGroup/${rateGroupId}`);
	}

	enableRateGroup(rateGroupId: string) {
		return this.apiService.get<ActionResult>(`/rateGroup/EnableRateGroup/${rateGroupId}`);
	}

	retireRateGroup(rateGroupId: string) {
		return this.apiService.get<ActionResult>(`/rateGroup/RetireRateGroup/${rateGroupId}`);
	}
}
